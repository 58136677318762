<template>
  <Layout>
    <div class="app-discount-edit">
      <a-page-header title="秒杀" sub-title="创建限时抢购活动" />
      <a-form-model :model="form" ref="form">
        <div class="adv-form">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item
                required
                label="活动开始时间"
                prop="startTime"
                :rules="{
                  required: true,
                  message: '开始时间必填'
                }"
              >
                <a-date-picker
                  v-model="form.startTime"
                  valueFormat="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  :disabled-date="disabledStartDate"
                  :show-time="{ format: 'h:mm' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item
                required
                label="活动结束时间"
                prop="endTime"
                :rules="{
                  required: true,
                  message: '结束时间必填'
                }"
              >
                <a-date-picker
                  v-model="form.endTime"
                  valueFormat="YYYY-MM-DD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  :disabled-date="disabledEndDate"
                  :show-time="{ format: 'h:mm' }"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>

        <div class="tool">
          <a-button @click="openProductDialog">
            <a-icon type="plus" /> 添加商品
          </a-button>
        </div>

        <a-table
          class="table-wrap"
          :columns="columns"
          :data-source="form.rows"
          :pagination="false"
          rowKey="productId"
        >
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="salePrice" slot-scope="price, record, index">
            <a-input-number
              :value="price"
              @change="val => changePrice(val, index)"
              :min="0.01"
              :max="record.price"
              :step="0.1"
            />
          </span>
          <span slot="saleStock" slot-scope="stock, record, index">
            <a-input-number
              :value="stock"
              @change="val => changeStock(val, index)"
              :min="1"
              :max="99999"
            />
          </span>
          <span slot="action" slot-scope="_, record, index">
            <a-button type="link" @click="delItem(index)">
              删除
            </a-button>
          </span>
        </a-table>
        <div style="height:100px" />

        <div class="footer">
          <a-button type="primary" html-type="submit" @click="submitForm">
            保存
          </a-button>
          <a-button style="margin-left: 10px" @click="cancel">
            取消
          </a-button>
        </div>
      </a-form-model>
    </div>

    <!-- 商品选择器 -->
    <ProductDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      only-self-product
      :multiple="true"
    />
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const moment = require("moment");

const LimitedDiscountSvc = require("@/service/limited-discount");

import loadDataMixin from "@/mixins/loadData";
import productMixin from "@/mixins/product";
import textMixin from "@/mixins/text";

const columns = [
  {
    title: "图片",
    align: "center",
    dataIndex: "img",
    key: "img",
    width: 60,
    scopedSlots: { customRender: "img" }
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "原价",
    align: "right",
    dataIndex: "price",
    key: "price",
    width: 100
  },
  {
    title: "秒杀价格",
    align: "center",
    dataIndex: "salePrice",
    key: "salePrice",
    width: 100,
    scopedSlots: { customRender: "salePrice" }
  },
  {
    title: "秒杀数量",
    align: "center",
    dataIndex: "saleStock",
    key: "saleStock",
    width: 100,
    scopedSlots: { customRender: "saleStock" }
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    width: 80,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "LimitedDiscountEdit",

  data() {
    return {
      form: {
        startTime: null,
        endTime: null,
        rows: []
      }
    };
  },

  computed: {
    columns() {
      return columns;
    }
  },

  mixins: [loadDataMixin, productMixin, textMixin],

  methods: {
    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.form.rows.findIndex(x => x.id === val.id) > -1) {
          continue;
        }

        const { id, title, img, price } = val;
        const salePrice = parseFloat(price),
          saleStock = 1;
        this.form.rows.push({
          id,
          title,
          img,
          price: parseFloat(price),
          salePrice,
          saleStock
        });
      }
    },
    delItem(index) {
      this.form.rows.splice(index, 1);
    },

    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.form.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    changePrice(val, index) {
      this.form.rows[index].salePrice = val;
    },
    changeStock(val, index) {
      this.form.rows[index].saleStock = val;
    },

    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let { startTime, endTime, rows } = this.form;
          if (rows.length <= 0) {
            this.$message.error("请先添加商品");
            return;
          }

          const line = JSON.stringify(
            rows.map(x => ({
              id: x.id,
              price: x.salePrice,
              stock: x.saleStock
            }))
          );
          try {
            let val = {
              startTime,
              endTime,
              line
            };

            await LimitedDiscountSvc.create(val);

            const msg = "创建成功";
            this.$message.info(msg);
            this.$router.replace({ name: "LimitedDiscountList" });
          } catch (err) {
            console.error(err);
            this.$message.error(
              "创建失败，请检查秒杀价与规格库存是否充足，一个商品仅限一个秒杀活动。"
            );
            // this.$message.error("创建活动失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ name: "LimitedDiscountList" });
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-discount-edit {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    table {
      width: 100%;

      img {
        width: 100px;
        max-height: 100px;
      }
    }
  }

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }

  .table-wrap {
    background: #fff;
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
}
</style>
