var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"app-discount-edit"},[_c('a-page-header',{attrs:{"title":"秒杀","sub-title":"创建限时抢购活动"}}),_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[_c('div',{staticClass:"adv-form"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"required":"","label":"活动开始时间","prop":"startTime","rules":{
                required: true,
                message: '开始时间必填'
              }}},[_c('a-date-picker',{attrs:{"valueFormat":"YYYY-MM-DD HH:mm","format":"YYYY-MM-DD HH:mm","disabled-date":_vm.disabledStartDate,"show-time":{ format: 'h:mm' }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"required":"","label":"活动结束时间","prop":"endTime","rules":{
                required: true,
                message: '结束时间必填'
              }}},[_c('a-date-picker',{attrs:{"valueFormat":"YYYY-MM-DD HH:mm","format":"YYYY-MM-DD HH:mm","disabled-date":_vm.disabledEndDate,"show-time":{ format: 'h:mm' }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)],1)],1)],1),_c('div',{staticClass:"tool"},[_c('a-button',{on:{"click":_vm.openProductDialog}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加商品 ")],1)],1),_c('a-table',{staticClass:"table-wrap",attrs:{"columns":_vm.columns,"data-source":_vm.form.rows,"pagination":false,"rowKey":"productId"},scopedSlots:_vm._u([{key:"img",fn:function(img){return _c('span',{},[_c('img',{staticClass:"product-img",attrs:{"src":img}})])}},{key:"salePrice",fn:function(price, record, index){return _c('span',{},[_c('a-input-number',{attrs:{"value":price,"min":0.01,"max":record.price,"step":0.1},on:{"change":val => _vm.changePrice(val, index)}})],1)}},{key:"saleStock",fn:function(stock, record, index){return _c('span',{},[_c('a-input-number',{attrs:{"value":stock,"min":1,"max":99999},on:{"change":val => _vm.changeStock(val, index)}})],1)}},{key:"action",fn:function(_, record, index){return _c('span',{},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.delItem(index)}}},[_vm._v(" 删除 ")])],1)}}])}),_c('div',{staticStyle:{"height":"100px"}}),_c('div',{staticClass:"footer"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.submitForm}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")])],1)],1)],1),_c('ProductDialog',{ref:"productDialog",attrs:{"id":"productDialog","only-self-product":"","multiple":true},on:{"selected":_vm.addItem}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }